/* KOLORY UŻYTE W PROJEKCIE
-jasne szarości: rgb(124, 124, 124);
-bordo: rgb(75, 12, 15);
-ciemne szarości:  color: rgb(39, 39, 39);
-kremowy   color: #eaebe3;
- żółty: rgb(241, 175, 37); - logo
- musztarda: rgb(221, 161, 32);



FONTY
  font-family: p22-pouty-pro, sans-serif;
*/

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
header {
  position: relative;
}
.app {
  display: flex;
  max-width: 100%;
  flex-direction: column;
  margin: 0 auto;

  font-family: "Catamaran", "Helvetica", "Arial" sans-serif;
  font-weight: 100;
  text-align: center;
  overflow: hidden;
}

.scrollTop {
  position: fixed;
  font-size: 35px;
  color: rgb(221, 161, 32);
  right: 23px;
  bottom: -10px;
  align-items: right;

  justify-content: center;
  z-index: 1000;
  cursor: pointer;
  animation: fadeIn 0.3s;
  transition: opacity 0.4s;
  opacity: 0.7;
}

.scrollTop:hover {
  opacity: 1;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}
.react-images__view-image {
  max-height: 100vh !important;
}
