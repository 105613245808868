/* WRAPPER */
.about {
  vertical-align: center;
  margin: 80px auto;
  padding-bottom: 250px;
  margin-bottom: 400px;
}
@media (max-width: 1280px) {
  .about {
    margin-top: 50px;
  }
}
@media (max-width: 1024px) {
  .about {
    margin-top: 40px;
  }
}
@media (max-width: 800px) {
  .about {
    margin-top: 10px;
    padding-bottom: 0px;
    margin-bottom: 350px;
  }
}
@media (max-width: 450px) {
  .about {
    margin-bottom: 300px;
  }
}

/* SEKCJA INFO - NAPISY */
.info {
  float: left;
  padding: 25px 8vw 60px 10vw;
  border-right: 1px solid rgb(75, 12, 15);
  margin: auto;
  width: 50%;
}

@media (max-width: 1668px) {
  .info {
    padding: 5px 6vw 30px 8vw;
  }
}
@media (max-width: 800px) {
  .info {
    float: none;
    border: none;
    width: 80%;
    padding-bottom: 0;
  }
}
@media (max-width: 414px) {
  .info {
    width: 90%;
  }
}

.about h1 {
  font-weight: 300;
  font-size: 30px;
  color: rgb(75, 12, 15);
  margin-bottom: 20px;
}
@media (max-width: 1024px) {
  .about h1 {
    font-size: 22px;
  }
}
@media (max-width: 800px) {
  .about h1 {
    font-size: 20px;
  }
}
/* @media (max-width: 414px) {
  .about h1 {
    font-size: 16px;
  }
} */
.about h2 {
  font-weight: 300;
  font-size: 30px;
  color: rgb(75, 12, 15);
  font-family: p22-pouty-pro, sans-serif;
  margin-bottom: 5px;
  margin-top: 50px;
}

.about h3 {
  font-weight: 300;
  font-size: 15px;

  color: rgb(124, 124, 124);
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

.about p {
  text-align: justify;
  text-indent: 1.5em;
  font-size: 18px;
}
@media (max-width: 1280px) {
  .about p {
    font-size: 15px;
  }
}

@media (max-width: 1024px) {
  .about p {
    margin-bottom: 30px;
  }
}

/* SEKCJA IMG */
.about aside {
  float: left;

  width: 50%;
  height: 40%;
}

.about aside img {
  width: 50%;
  margin-top: 15px;
  margin-bottom: 80px;
  margin-right: 15%;
  vertical-align: center;
  transition: 1s;
}

@media (max-width: 1668px) {
  .about aside img {
    margin-bottom: 180px;
    margin-top: 20px;
  }
}
@media (max-width: 1368px) {
  .about aside img {
    width: 60%;
    margin-bottom: 150px;
    margin-top: 50px;
  }
}
@media (max-width: 1280px) {
  .about aside img {
    margin-bottom: 100px;
    margin-top: 0px;
  }
}
@media (max-width: 1024px) {
  .about aside img {
    margin-bottom: 120px;
  }
}
@media (max-width: 800px) {
  .about aside img {
    margin-bottom: 0;
    margin-right: 10px;
  }
}
@media (max-width: 700px) {
  .about aside img {
    margin-left: 60px;
  }
}
@media (max-width: 414px) {
  .about aside img {
    width: 90%;
    margin-left: 20px;
  }
}
/* sekcja added */
.added_about {
  width: 30vw;
  margin-top: 80px;
  float: left;
}

@media (max-width: 700px) {
  .added_about {
    width: 40vw;
    margin-top: 10px;
  }
}

@media (max-width: 500px) {
  .added_about {
    width: 40vw;
    margin-top: 0;
    margin-left: 0;
  }
}
@media (max-width: 414px) {
  .added_about {
    margin-left: 15px;
  }
}
.added_about h2 {
  font-size: 25px;
  margin-bottom: 5px;
  font-family: p22-pouty-pro, sans-serif;
}

/* @media (max-width: 500px) {
  .added_about h2 {
    font-size: 18px;
  }
} */
.added_about h3 {
  margin-top: 0;
  font-size: 13px;
  color: grey;
  text-transform: uppercase;
  letter-spacing: 1.5px;
}
/* @media (max-width: 414px) {
  .added_about h3 {
    font-size: 10px;
  }
} */
