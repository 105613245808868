.offer_wrapper {
  margin: 0 auto;
}
.offer {
  width: 70vw;
  margin: 30px auto;
  text-align: justify;
  text-indent: 2.5em;
}
@media (max-width: 1280px) {
  .offer {
    width: 75vw;
  }
}

.offer p {
  /* text-indent: 120px; */
  font-size: 18px;
  margin: 0;
}
@media (max-width: 850px) {
  .offer p {
    font-size: 15px;
  }
}

.photos_offer {
  /* overflow: hidden; */
  margin: 40px auto;
  max-width: 1700px;
}
@media (max-width: 900px) {
  .photos_offer {
    max-width: 800px;
  }
}
@media (max-width: 800px) {
  .photos_offer {
    margin-top: 20px;
  }
}

.contain_overlay {
  width: 33.3%;
  float: left;
}

@media (max-width: 650px) {
  .contain_overlay {
    float: none;
    width: 100%;
    /* margin: 0 auto; */
    /* width: 100vw; */
  }
}
.overlay {
  position: relative;
  width: 26vw;
  height: 32vw;
  margin: 0 auto;
  background-color: #15100c;
}
/* .overlay:hover {
  background-color: #eaebe3;
} */
@media (max-width: 650px) {
  .overlay {
    width: 80vw;
    height: 98.72vw;
    margin: 20px auto;
  }
}
.overlay h1 {
  font-family: p22-pouty-pro, sans-serif;
  position: absolute;
  top: 19vw;
  left: 0;
  width: 100%;
  margin: 0 auto;
  color: #eaebe3;
  font-size: 3vw;
  font-weight: 400;

  font-style: normal;
}

@media (max-width: 650px) {
  .overlay h1 {
    top: 59vw;
    font-size: 8vw;
  }
}
.hover_element {
  background-color: rgb(255, 255, 255, 0.9);
  color: rgb(39, 39, 39);
  position: absolute;
  top: 6%;
  margin-left: 10%;
  width: 80%;
  height: 86%;
  animation: fadein 1s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.hover_element div {
  margin-top: 4vw;
}
@media (max-width: 1380px) {
  .hover_element div {
    margin-top: 16%;
  }
}

.hover_element h2 {
  color: rgb(75, 12, 15);
  font-family: p22-pouty-pro, sans-serif;
  font-size: 2.5vw;
  margin-bottom: 2vw;
}
@media (max-width: 850px) {
  .hover_element h2 {
    font-size: 2.5vw;
  }
}
@media (max-width: 650px) {
  .hover_element h2 {
    font-size: 8vw;
    margin-bottom: 6vw;
  }
}

.hover_element section {
  text-transform: uppercase;
  font-size: 1vw;
  margin-bottom: 10%;
}
@media (max-width: 850px) {
  .hover_element section {
    font-size: 1.3vw;
  }
}
@media (max-width: 650px) {
  .hover_element section {
    font-size: 3vw;
  }
}

.hover_element section ul {
  list-style: none;
}

.photos_offer img {
  object-fit: cover;
  width: 26vw;
  opacity: 0.6;
}
@media (max-width: 650px) {
  .photos_offer img {
    width: 80vw;
  }
}
