.header {
  float: none;
}

.logo {
  width: 120px;
  margin-top: 25px;
}
/* @media (max-width: 650px) {
  .header .logo {
    margin-left: 14px;
  }
} */

@media (max-width: 820px) {
  .logo {
    width: 80px;
    margin-top: 8px;
    margin-bottom: 8px;
  }
}
.main_nav {
  text-align: center;
}

.main_nav ul {
  list-style-type: none;
  margin-top: 12px;
  margin-bottom: 13px;
}
.main_nav li {
  display: inline-block;
  text-transform: uppercase;
  margin: 0px 40px;
}
.main_nav li a {
  font-size: 13px;
  text-decoration: none;
  color: rgb(27, 27, 27);
}

.main_nav li a:hover {
  color: rgb(221, 161, 32);
}
.main_nav li a.active {
  color: rgb(221, 161, 32);
}

/* MOBILE */
.mobile {
  line-height: 1.2;
  position: absolute;
  font-size: 1.5rem;
  top: 8px;
  right: 28px;
  text-align: right;
  width: 100vw;
  z-index: 10;
}
