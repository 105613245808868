.d-block {
  height: 100vh;
  object-fit: cover;
}

@media (min-width: 768px) {
  .d-block {
    height: 100vh;
    width: auto;
  }
}

.carousel-item {
  transition: opacity 2s ease-in-out !important;
}

.carousel-img {
  max-height: 100%;
}
