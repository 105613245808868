.nav_mob {
  width: 100vw;

  margin-left: 0;
  vertical-align: middle;
  animation: fadein 1s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.nav_mob div {
  background-color: rgb(20, 20, 20, 0.9);
  text-align: left;
  padding: 5px 18px 10px 18px;

  text-transform: uppercase;

  border-top: 0.3px solid rgb(234, 235, 227, 0.5);
}
.nav_mob a {
  font-size: 13px;

  color: rgb(234, 235, 227);
}
.nav_mob a:hover {
  color: rgb(221, 161, 32);
  text-decoration: none;
}

.nav_mob a.active {
  color: rgb(221, 161, 32);
}
