.wrapper_footer {
  width: 100vw;
}
.icons_footer {
  vertical-align: middle;
  margin-top: 10px;
  padding-left: 180px;
  color: rgb(75, 12, 15);
  width: 50%;
  float: left;
  text-align: left;
  font-size: 20px;
}
.icons_footer a {
  color: rgb(75, 12, 15);
}

@media (max-width: 1300px) {
  .icons_footer {
    padding-left: 50px;
    width: 30%;
  }
}
@media (max-width: 500px) {
  .icons_footer {
    padding-left: 10px;
  }
}
.icons_footer a:hover {
  color: rgb(124, 124, 124);
  text-decoration: none;
}
.footer {
  width: 50%;
  float: left;
  font-size: 12px;
  line-height: 18px;
  text-align: right;
  padding: 10px 250px;
}
@media (max-width: 1300px) {
  .footer {
    width: 70%;
    line-height: 16px;
    padding-right: 90px;
    padding-left: 0;
  }
}

@media (max-width: 500px) {
  .footer {
    font-size: 10px;
    padding: 10px 30px;
    padding-right: 70px;
  }
}
.footer p {
  margin-bottom: 0px;
}
