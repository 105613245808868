.wrapper_faq {
  clear: both;
  margin: 0 auto;
  max-width: 90vw;
  padding-top: 5%;
}
.wrapper_faq p {
  text-align: justify;
  text-indent: 2em;
  font-size: 17px;
}
.wrapper_faq h1 {
  color: rgb(75, 12, 15);
  font-family: p22-pouty-pro, sans-serif;
  margin-bottom: 4.5%;
  font-size: 280%;
}
@media (max-width: 1300px) {
  .wrapper_faq h1 {
    font-size: 200%;
  }
}
.wrapper_faq li {
  font-weight: 300;
  text-transform: uppercase;
  font-size: 1vw;
  color: rgb(75, 12, 15);
}
.wrapper_faq h2 {
  font-weight: 500;
  text-transform: uppercase;
  font-size: 120%;
  color: rgb(75, 12, 15);
}
@media (max-width: 1300px) {
  .wrapper_faq h2 {
    font-size: 100%;
  }
}
/* .wrapper_faq div p {
  margin-bottom: 1vw;
} */
.faq_section {
  height: 20%;
  margin-bottom: 50px;
}
/* @media (max-width: 1600px) {
  .faq_section {
    height: 30vw;
  }
} */

.faq1 {
  float: left;
  width: 50%;
  padding-right: 5%;
  padding-left: 10%;
  margin-bottom: 50px;
}
@media (max-width: 1000px) {
  .faq1 {
    float: none;
    padding: 0;
    width: 100%;
  }
}

.faq2 {
  float: left;
  width: 50%;
  padding-right: 10%;
  padding-left: 5%;

  border-left: 0.1px solid rgb(75, 12, 15, 0.5);
}
@media (max-width: 1000px) {
  .faq2 {
    float: none;
    border-left: none;
    padding: 0;
    width: 100%;
    margin-bottom: 50px;
  }
}
